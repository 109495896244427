import { render, staticRenderFns } from "./OtherResources.vue?vue&type=template&id=305aebd0&scoped=true&"
import script from "./OtherResources.vue?vue&type=script&lang=js&"
export * from "./OtherResources.vue?vue&type=script&lang=js&"
import style0 from "./OtherResources.vue?vue&type=style&index=0&id=305aebd0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305aebd0",
  null
  
)

export default component.exports