<template>
    <div v-if="this.pageData && this.resourceData" >
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="opportunities" />
        <main class="resources">
            <section class="main-content">
                <div class="container">
                    <p class="note-top" v-html="this.pageData.attributes.field_description_w_summary.value"></p>
                    <div class="attachments-container">
                        <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                    </div>
                </div>

                
                <div class="container">
                    <div @click="collapse" :class="['collapsing filter-container', this.collapsed ? 'drop-down' : 'drop-up']">
                        <h3>Filter by category</h3>
                    </div>
                    <div :class="['filters-row', this.collapsed ? 'collapsed' : '']">
                        <button v-for="(c, ind) in categories" :key="'category'+ind" 
                        :class="[catFilter.includes(c) ? 'active': '']"
                        @click="toggleFilter(c)">{{ c }}</button>
                    </div>
                </div>

                <div v-for="(cat, ind) in categories" :key="'cat'+ind" ref="cat">
                    <div class="container" v-if="filteredResources.filter(r =>  r.attributes.field_resource_category.includes(cat)).length">
                        <h2>{{ cat }}</h2>
                        <div class="container-items">
                            <div class="item" v-for="(r, index) in filteredResources.filter(r =>  r.attributes.field_resource_category.includes(cat))" :key="'person'+index">
                                <h2>{{ r.attributes.title }}</h2>
                                <p v-html="r.attributes.field_description.value"></p>
                                <p><a target="_blank" :href="r.attributes.field_url.uri">Go to website</a></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="note-bottom" v-if="this.pageData.field_ref_ui_text.length" 
                    v-html="this.pageData.field_ref_ui_text[0].attributes.field_description_w_summary.value">
                </div>
            </section>

        </main>
    </div>
</template>


<script>
import Attachments from '../components/Attachments.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes, fetchSinglePage } from '../libs/drupalClient'
    export default {
        name: 'other-resources',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "558937bf-7a13-4849-8feb-559ab74d90df",
                resourceData: null,
                categories: [
                    'Online resources',
                    'Organizations providing support and opportunities',
                    'On line job matching platforms',
                    'International Organizations',
                    'Other relevant opportunities and organizations',
                    'Research, policy and advocacy'
                ],
                catFilter: [],
                collapsed: true
            }
        },
        computed: {
            filteredResources() {
                var rs = []

                if(this.resourceData) {
                    rs = this.resourceData

                    if(this.catFilter.length) {
                        rs = rs.filter(r => {
                            let found = false
                            r.attributes.field_resource_category.forEach(f => {
                                if(this.catFilter.includes(f))
                                    found = true
                            })

                            return found
                        })
                    }
                }
                
                return rs
            }
        },
        methods: {
            collapse() {
                this.collapsed = !this.collapsed
            },
            toggleFilter(c) {
                if(!this.catFilter.includes(c))
                    this.catFilter.push(c)
                else 
                    this.catFilter.splice(this.catFilter.indexOf(c), 1)
            }
        },
        mounted() {
            fetchSinglePage(this.currentPageID, {
                include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text','field_attachments']
            })
            .then(res => {
                this.pageData = res
                //console.log('pagedata', res)
            })

            fetchNodes('resource', {})
            .then(res => {
                //console.log('all resources', res)
                this.resourceData = res
            })
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

    .note-top ::v-deep strong, 
    .note-bottom ::v-deep strong {
        font-size: 1.5em;
        line-height: 1rem;
        color: $orange;
        font-weight: 700;
    }

    .note-bottom ::v-deep p {
        font-size: .9rem;
        line-height: 1.2rem;
    }

.closed {
    >div {
        display: none;
    }
}
    .resources {
        background-color: #EBF0F5;
        .container>h2 {
            margin-top: 3rem;
            margin-bottom: 1rem;
        }
    }

    .item {
        border-radius: 5px;
        margin-bottom: 2rem;
        padding: 2rem 1rem;
        background-color: #fff;
        h2 {
            font-size: 1.375rem;
            color: $green;
            line-height: 1.7rem;
        }
        p { max-width: none; }
    }

    .container-items {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
    }

    @media (min-width: 768px) {
        .filters-row {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: 1024px) {
        .container-items { grid-template-columns: repeat(2, 1fr); }
        .item { grid-column: span 1; }
        .filters-row { grid-template-columns: repeat(3, 1fr); }

        .resources {
            .container>h2 {
                margin-top: 4rem;
            }
        }
        .container-items {
            .item {
                //width: 27%;
                padding: 2rem 2rem;
                h2 {
                    font-size: 1.75rem;
                    line-height: 2.1875rem;
                }
            }
        }
    }

    @media (min-width: 1300px) {
        .container-items { grid-template-columns: repeat(3, 1fr); }
        .filters-row { grid-template-columns: repeat(4, 1fr); }
    }
</style>